/*
 * 업무구분: 수익증권>파일재전송>파일재전송
 * 화 면 명: MSPFS320M
 * 화면설명: 파일재전송
 * 작 성 일: 2023.06.13
 * 작 성 자: 송진의
 */
<template>
    <ur-page-container
      class="fts"
      :show-title="false"
      :title="pHeaderObj.title"
      :topButton="true"
    >
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">                 
        <p class="desc02 mb-1"><em class="colorR">파일은 오류 시점부터 D+7까지만 보관됩니다.</em></p>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th> 오류 시점 </th>
                <th> 파일명 </th>
                <th class="w200"> 녹취파일 전송 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in lv_recdFileShowList" :key="i">
                <td>
                  {{$bizUtil.dateYyMmDdHhMmFormat(row.recdTrtDtm)}}
                </td>
                <td>
                  <div v-if="row.value2 != ''">
                      {{fn_SetFileNm(row.recdFileNm)}}
                    </div>
                    <div v-else>
                      -
                    </div>
                </td>
                <td> <!-- [D] Y: 대상아님 / N: 재전송 Button -->
                  <div v-if="row.nrmTrtYn == 'N'">
                    <mo-button @click="fn_ReTrns(row)"> 재전송 </mo-button>
                  </div>
                  <div v-else>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ur-box-container v-if="lv_serverTyp == 'local' || lv_serverTyp == 'development'">
          <div class="wrap-button button-page-bottom row">
            <mo-button point size="large" @click="fn_ReTrnsTest"> 재전송 전체 미처리(T) </mo-button>            
          </div>
        </ur-box-container>        
        <!-- Pagination -->
        <div class="wrap-pagination mt-3">
          <span class="float-left">
            총 {{ $bizUtil.numberWithCommas(lv_page.totalItems) }} 건
          </span>
          <mo-pagination v-if="lv_page.totalItems > 0"
            :pagination="lv_page"
            @move="fn_PageChange"
          />
        </div>
        <!-- //Pagination -->
		  </ur-box-container>
    </div>
    <!-- //.fts-main -->
    
    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSHeader from "@/ui/fs/comm/FSHeader"
  import FSFileUtil from "@/ui/fs/comm/FSFileUtil"
  import FSCommUtil from "@/ui/fs/comm/FSCommUtil"
  import FSAlertPopup from "@/ui/fs/comm/FSAlertPopup"; // Alert 팝업 (공통)
  import FSInfoUtil from "@/ui/fs/comm/FSInfoUtil"; // 공통정보유틸 
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS320M",
    screenId: "MSPFS320M",
    components: {
      "fs-header": FSHeader,
      "fs-alert-popup": FSAlertPopup,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.$nextTick(()=>{
        this.lv_serverTyp = process.env.NODE_ENV
        this.fn_SearchList()
      })
    },
    mounted() {
      this.fn_Init()
      this.$bizUtil.insSrnLog('MSPFS320M')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {
          title: "파일재전송",
          step: "",
        },
        /**           
          busn_sc_cd	업무구분코드
          recd_ymd	녹취일자
          recd_str_tm	녹취시작시간
          recd_gude_eno	녹취안내자사번
          recd_tot_phcl_tm	녹취총통화시간
          recd_file_nm	녹취파일명
          cust_id	고객ID
          cust_nm	고객명
          cust_rrn_encr	고객주민등록번호암호화
          cust_telno_encr	고객전화번호암호화
          nrm_trt_yn	정상처리여부
          lst_trt_yn	최종처리여부
          recd_trt_dtm	녹취처리일시
          ernam	생성자
          erdat	생성일
          erzet	생성시간
          aenam	변경자
          aedat	변경일
          aezet	변경시간
        */
        // 팝업 객체
        pAlertPopupObj: {},        
        // Pagination
        lv_page: {
          rowsPerPage: 10,
          currentPage: 1,
          totalPages: 0,
          totalItems: 0,
        },

        lv_basInfo: this.getStore("fsStore").getters.getBasInfo.data,
        lv_recdFileSearchList: [], // 녹취파일목록
        lv_recdFileShowList: [],   // 녹취파일페이지별 목록
        lv_selectRow: {}, // 선택한 파일목록
        lv_serverTyp: '',

        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // modal
      modal1() {return this.$refs.modal1},
      modal2() {return this.$refs.modal2},
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 초기화
       ******************************************************************************/
      fn_Init() {
        this.lv_recdFileList = []
        this.lv_page = {
          rowsPerPage: 10,
          currentPage: 1,
          totalPages: 0,
          totalItems: 0,
        }
      },
      /******************************************************************************
        * Function명 : fn_AlertPopup
        * 설명       : alert popup 호출(공통)
        ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },
      /******************************************************************************
       * Function명 : fn_SearchList, fn_SearchListResult
       * 설명       : 녹취파일목록조회
       ******************************************************************************/
      async fn_SearchList() {
        // let userEno = localStorage.getItem('userEno')
        let param = {
          busnScCd: '05',
          // recdGudeEno: userEno
        }
        await FSFileUtil.gfn_searchRecodeList(this, param, this.fn_SearchListResult)
      },
      async fn_SearchListResult (pResultData) { 
        console.log('fn_SearchListResult...')
        console.log(pResultData)
        let resData = pResultData.recdFileInfoList.filter(v=> (v.nrmTrtYn === 'N'))
        let tmpRecdFileInfoList = []        
        if(process.env.NODE_ENV !== 'local'){
          tmpRecdFileInfoList = await this.$commonUtil.recListOnDevice(this)
          tmpRecdFileInfoList = tmpRecdFileInfoList.replace(/\[(.*)\]/, '$1').split(', ')
        } else {
          // tmpRecdFileInfoList = ['20230913162601_1000026248.mp3.crypt','20230907184919_1000026248.mp3.crypt']
        }

        let filtered = {}
        if(resData.length > 0) {
          if(!this.$bizUtil.isEmpty(tmpRecdFileInfoList) && tmpRecdFileInfoList.length > 0) {
            resData.forEach(item=> {
              filtered = tmpRecdFileInfoList.filter(v => v === item.recdFileNm)[0]
              if(!this.$bizUtil.isEmpty(filtered)){
                this.lv_recdFileSearchList.push(item)
              }
            })
          } else {
            this.lv_recdFileSearchList =  []
          }
        }

        this.lv_page.totalPages = this.lv_recdFileSearchList.length / 10
        this.lv_page.totalItems = this.lv_recdFileSearchList.length

        this.fn_PageChange(1)
      },
      /******************************************************************************
       * Function명 : fn_PageChange
       * 설명       : 페이징 처리
       ******************************************************************************/
      fn_PageChange(pageNum) {
        console.log('fn_PageChange.....')

        this.lv_isFund = null
        this.lv_recdFileShowList = []

        let strNum = (pageNum - 1) * this.lv_page.rowsPerPage
        let endNum = pageNum * this.lv_page.rowsPerPage

        for(let i=strNum; i<endNum; i++) {
          if( this.lv_recdFileSearchList[i] ) {
            this.lv_recdFileShowList.push(this.lv_recdFileSearchList[i])
          }
        }
      },
      /******************************************************************************
       * Function명 : fn_SetFileNm
       * 설명       : 파일명 설정
       ******************************************************************************/
      fn_SetFileNm(fileNm){
        return fileNm.replace(/.crypt/g, "")
      },      
      /******************************************************************************
       * Function명 : fn_ReTrns
       * 설명       : 파일재전송
       ******************************************************************************/
      fn_ReTrns(obj){        
        console.log('fn_ReTrns >', obj)
        this.lv_selectRow = obj
        FSFileUtil.gfn_transRecordFile(this, obj, this.fn_ReTrnsResult)
      },
      fn_ReTrnsResult (pResultData) {        
        console.log('fn_ReTrnsResult...')
        console.log(pResultData)
        if(pResultData.respCd === '0'){          
          this.fn_AlertPopup(0, {content: '작업이 완료되었습니다.'})
        }        
      },
      fn_ReTrnsTest() {
        let lv_Vm = this
        if(lv_Vm.lv_recdFileShowList.length === 0){
          return
        }
        lv_Vm.lv_recdFileShowList.forEach(item =>{
          let param = item        
          param.lstTrtYn = 'N'
          FSFileUtil.gfn_udtRecodeData(lv_Vm, param, lv_Vm.fn_ReTrnsTestResult)
        })
      },
      fn_ReTrnsTestResult (pResultData) {
        console.log('fn_ReTrnsTestResult...')
      },
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
